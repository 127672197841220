.game-over-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 999;
}

.game-over-title {
  color: #eb2626;
  margin-bottom: -10px;
  font-size: 2em;
  text-shadow:
    -1px -1px 0 #131413,
    1px -1px 0 #131413,
    -1px 1px 0 #131413,
    1px 1px 0 #131413;
}

.score {
  font-size: 1em;
}

.button-container {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  background-color: #8098ad;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button:hover {
  background-color: #3498db;
}