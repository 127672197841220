body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  line-height: 1.4;
  margin: 20px;
  background-color: #f9f9f9;
  color: #333;
}

section {
  max-width: 850px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
}

h1 {
  text-align: center;
}

h1,
h2,
h3 {
  color: #3498db;
}

h2 {
  margin-top: 40px;
}

h1.main-heading {
  color: #3498db;
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
}

h2.email-heading {
  text-align: center;
  font-size: 1em;
  margin-top: 5px;
}

h3 {
  margin-top: 20px;
  padding-inline: 5px;
}

h4 {
  margin-left: 10px;
}

li {
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
}

.italic-text {
  font-style: italic;
  color: grey
}

.separator {
  font-style: normal;
  color: inherit;
}


.contact-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.contact-icons img {
  width: 30px;
  margin-right: 10px;
}