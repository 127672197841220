.header {
  background-color: #3498db;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-content {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0 40px;

}

.start-button-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}


.score-wrapper {
  text-align: center;
  flex: 1;
}

.highscore-wrapper {
  flex: 1;
  text-align: right;
}

.start-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #a7a7a774;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  border-radius: px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.start-button:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.start-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.highscore {
  font-size: 16px;
}

@media (max-width: 768px) {
  .header-content {
    padding: 0 20px;
  }
}